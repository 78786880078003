<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="9">
        <v-stepper v-model="adsData.step" :value="adsData.step" alt-labels>
          <v-stepper-header>
            <template v-for="(page, index) in pages">
              <v-stepper-step :complete="adsData.step > page.step" :step="page.step" :key="`${index}-step`">
                <span v-if="adsData.step === page.step" class="rouge--text">{{ page.title }}</span>
              </v-stepper-step>
              <v-divider v-if="index !== pages.length - 1" :key="index"></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content
              v-for="(page, index) in pages"
              :key="`${index}-content`"
              :step="page.step"
              class="ma-0 pa-0 grey lighten-5">
              <v-card color="grey lighten-5" flat>
                <keep-alive>
                  <component :is="page.component" v-bind="curProps" v-dynamic-events="customEvents"></component>
                  <!--<component :is="page.component" :="curProps" v-dynamic-events="saveEvents"></component>-->
                  <!--<component :is="page.component" v-model="adsData"></component>-->
                  <!--<component :is="page.component" :value="adsData" @:input="adsData = arguments[0]"></component>-->
                  <!--<component :is="page.component" :value="adsData" @:input="adsData = $event.target.value"></component>-->
                </keep-alive>

                <div
                  :class="
                    adsData.category.firstName === '매장'
                      ? $vuetify.breakpoint.mdAndUp
                        ? 'shades white px-10 pb-12'
                        : 'shades white pa-6 pb-12'
                      : 'shades white pa-6 pb-12'
                  "
                  style="display: flex; justify-content: space-between">
                  <v-btn
                    v-if="index !== 0"
                    color="primary-light"
                    class="ma-0"
                    text
                    outlined
                    @click="prevStep()"
                    style="width: 120px"
                    >이전</v-btn
                  >
                  <div v-if="index === 0"></div>
                  <v-btn
                    v-if="index !== pages.length - 1"
                    class="primary-light white-two--text ma-0 ml-4"
                    @click="checkRequiredInfo(page.step)"
                    :style="'opacity:' + (page.done ? '1.0' : '0.2') + '; width:120px'">
                    다음
                  </v-btn>

                  <v-btn
                    v-else
                    color="primary-light"
                    class="ma-0 mr-4 white-two--text"
                    @click="adsData.category.firstName === '매장' ? (showComplete = true) : registerProductAds()"
                    style="float: right; width: 120px">
                    광고등록
                  </v-btn>
                </div>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="showStop" content-class="white-two" persistent max-width="500px">
        <v-card class="px-6 pt-2 pb-4">
          <v-card-title class="pb-1">
            <div>
              <v-icon color="default" @click="showStop = false">close</v-icon>
              <h2 class="mt-4 font-weight-bold headline">광고 등록에 어려움이 있으신가요?</h2>
            </div>
          </v-card-title>
          <v-card-text>
            <div class="body-1">
              광고 등록에 관해 궁금한 점이 있으시다면,
              <span class="rouge--text">오른쪽 하단 채팅버튼</span>을<span class="desktop" /> 클릭하여 도움을 받으실 수
              있습니다.<span class="desktop" /> 현재 작성중인 광고를 임시 저장하고, 언제든지 현재 페이지로<span
                class="desktop" />
              돌아오실 수 있습니다.
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn class="rouge white-two--text" text @click.native="showStop = false">뒤로</v-btn>
            <v-btn
              color="rouge"
              class="ma-0 ml-4"
              text
              outlined
              @click="
                showStop = false;
                save();
                $router.push('/company/report');
              "
              >임시저장 후 종료</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="showComplete"
        content-class="white-two m-6"
        style="border-radius: 20px"
        persistent
        max-width="375px">
        <v-card class="pa-6">
          <v-card-title class="pb-3 d-flex flex-column align-start">
            <div class="mb-3 d-flex justify-end" style="width: 100%">
              <v-icon color="default" @click="showComplete = false">close</v-icon>
            </div>

            <div>
              <h2 class="font-weight-bold headline">광고 정보 입력 완료 🙌</h2>
            </div>
          </v-card-title>
          <vDivider />
          <v-card-text class="pt-6 pb-6">
            <span class="body-1">
              지금 결제 정보를 등록하면<br />

              <span class="rouge--text font-weight-bold"> 3개월 플랜 20% 할인권</span>을 받을 수 있어요! <br /><br />
              지금 바로 카드를 등록할까요?
            </span>
          </v-card-text>
          <v-card-actions class="pa-0 d-flex justify-space-between">
            <v-btn
              class="ma-0 dialog-button"
              color="primary-light"
              text
              outlined
              @click="
                showComplete = false;
                registerStoreAds(false);
              "
              >상담 받고 <br />나중에 등록하기</v-btn
            >
            <v-btn
              class="primary-light white-two--text dialog-button"
              @click="
                showComplete = false;
                registerStoreAds(true);
              ">
              지금 등록하고
              <br />20% 할인 받기
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showRequiredInfoDlg" content-class="white-two" persistent max-width="500px">
        <v-card class="px-8 pt-2 pb-2">
          <v-card-title>
            <div>
              <v-icon color="default" @click="showRequiredInfoDlg = false">close</v-icon><br />
              <h2 class="mt-2 font-weight-bold headline">누락된 필수 정보가 있습니다. 계속 진행하시겠습니까?</h2>
            </div>
          </v-card-title>
          <v-card-text>
            <span class="body-1">
              필수 정보를 모두 입력해주셔야 광고를 개시할 수 있습니다.<br />
              미입력된 정보는 광고 등록 완료 후 광고 관리 페이지에서도 입력하실 수 있습니다.
            </span>
          </v-card-text>
          <v-card-actions class="px-0 pt-2 pb-6">
            <v-spacer></v-spacer>
            <v-btn color="rouge" outlined flat @click="showRequiredInfoDlg = false">취소</v-btn>
            <v-btn
              color="rouge white-two--text"
              class="ma-0 ml-4"
              @click="
                showRequiredInfoDlg = false;
                nextStep(adsData.step);
              "
              >넘어가기</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { EventBus } from '@/components/shared/event-bus';
import userMixin from '@/components/shared/userMixin';
import companyMixin from '@/components/shared/companyMixin';
import * as AD from '@/constants/ad';

export default {
  name: 'Regads',
  mixins: [userMixin, companyMixin],
  components: {},
  data() {
    return {
      adsData: {
        category: {
          firstName: '매장',
          secondName: '',
          thirdName: '',
        },
        is_verified: 99,
        step: 1,
        operationType: AD.STORE_OPERATION_TYPE.MASS_REVIEW,
        isReservationRequired: 0,
        plan: 1,
        reservationComment: null,
        naverPlaceId: null,
        maxPrice: 0,
      },
      option: { category: {} },
      pages: [],
      showComplete: false,
      customEvents: {
        updateEvent: e => {
          // 제품, 매장 유형 변경 시 adsData 초기화
          if (e.category && e.category.firstName !== this.adsData.category.firstName) {
            this.adsData = {
              is_verified: 99,
              step: 1,
              id: this.adsData.id,
            };
          }

          Object.keys(e).forEach(key => {
            this.$set(this.adsData, key, e[key]);
          });

          // 필드 유효성 검사

          let page = this.pages[e.step - 1];

          if (this.adsData.category.firstName === '매장') {
            if (this.isRequiredFieldsFilled) {
              page.done = true;
            } else {
              page.done = false;
            }
          } else {
            page.done = true;
          }
          this.$set(this.pages, e.step - 1, page);
        },
      },
      timer: null,
      showRequiredInfoDlg: false,
      requiredMap: {
        매장: {
          1: ['category.firstName', 'name', 'naver.diamondPrice'],
          2: [],
          3: [],
          4: [],
        },
        제품: {
          1: [],
          2: ['name', 'site', 'companyName', 'csNumber'],
          3: ['abstraction', 'introduction'],
          4: {
            A: ['naver.number', 'naver.diamond', 'keywords'],
            S: ['naver.number', 'naver.black', 'naver.red', 'naver.yellow', 'keywords'],
          },
          5: [],
          6: ['thumbnails'],
        },
      },
    };
  },
  computed: {
    curProps: function () {
      return {
        adsData: this.adsData,
        option: this.option,
      };
    },

    showStop: {
      set(showDialog) {
        this.$store.commit('setShowDialog', showDialog);
      },
      get() {
        return this.$store.getters.showDialog;
      },
    },

    isRequiredFieldsFilled() {
      return (
        this.requiredMap[this.adsData.category.firstName][this.adsData.step][this.adsData.type] ||
        this.requiredMap[this.adsData.category.firstName][this.adsData.step]
      ).every(field => {
        if (field.indexOf('.') > 0) {
          return (
            !!this.adsData[field.split('.')[0]]?.[field.split('.')[1]] &&
            !this._.isNil(this.adsData[field.split('.')[0]]?.[field.split('.')[1]])
          );
        } else {
          return !!this.adsData[field] && !this._.isNil(this.adsData[field]);
        }
      });
    },
  },

  watch: {
    companyTemp(value) {
      this.initCompany(value);
    },
    'adsData.category.firstName': function (value) {
      // 카테고리 변경 시, 초기화
      this.adsData.category = { ...this.adsData.category, firstName: value, first: '' };
      this.initPages(value);
    },

    'adsData.step': function (value) {
      let page = this.pages[value - 1];

      if (this.isRequiredFieldsFilled) {
        page.done = true;
      }

      this.$set(this.pages, value - 1, page);

      window.dataLayer.push({
        event: 'ad_step_info',
        adRegisterStep: value,
      });

      this.$store.commit('setCompanyRegStep', value);
    },
  },

  methods: {
    async initCompany(company) {
      if (company.is_verified === 99) {
        this.adsData = JSON.parse(JSON.stringify(company));
        this.adsData.step = company.step;

        if (this.adsData.category.firstName === '매장') {
          this.adsData.maxPrice =
            this.adsData.naver.diamondPrice ||
            this.adsData.naver.blackPrice ||
            this.adsData.naver.redPrice ||
            this.adsData.naver.yellowPrice ||
            0;
          this.adsData.plan = this.adsData.naver.payInfo_plan || 1;
        }

        this.changeDonePreviousSteps();
      } else {
        // NOTE: 임시 저장된 것이 없었을 때 초기값 세팅
        this.$store.commit('setCompanyTemp', this.adsData);
      }
    },

    async initPages(value) {
      const maxStep = value === '매장' ? AD.STORE_MAX_STEP : AD.PRODUCT_MAX_STEP;
      const titles = value === '매장' ? AD.STORE_PAGE_TITLE : AD.PRODUCT_PAGE_TITLE;

      let pages = [];
      for (let i = 0; i < maxStep; i++) {
        let page = {};

        page.step = i + 1;
        page.title = titles[i];
        page.done = value === '매장' ? false : true;

        page.component = () =>
          import('../../components/company/regads/' + (value === '매장' ? 'store' : 'product') + '/Page' + (i + 1));

        pages.push(page);
      }

      if (this.requiredMap[this.adsData.category.firstName][this.adsData.step]) {
        if (this.isRequiredFieldsFilled) {
          pages[this.adsData.step - 1].done = true;
        }
      }

      this.pages = pages;
    },

    async prevStep() {
      this.adsData.step = Math.max(this.adsData.step - 1, 1);
    },

    async nextStep(step) {
      if (step) {
        this.changeDonePreviousSteps();
      }

      await this.save();
    },

    getStoreUpdateBody() {
      let body = {
        companyId: this.adsData.user_id || null,
        tempAdId: this.adsData.id || null,
        adInfo: {
          email: this.adsData.email || null,
          companyName: this.user.name || null,
          adName: this.adsData.name || null,
          plan: this.adsData.plan || 1,
          x: this.adsData.x || null,
          y: this.adsData.y || null,
          adCategory: 'STORE',
          adType: this.adsData.type === 'A' ? 'PREMIUM' : 'STANDARD',
          step: this.adsData.step,
          is_verified: this.adsData.is_verified,
          operationType: this.adsData.operationType || 'MASS_REVIEW',
          isReservationRequired: this.adsData.isReservationRequired || 0,
          reservationComment: this.adsData.reservationComment,
          naverPlaceId: this.adsData.naverPlaceId || null,
        },
        priceInfo: { red: {}, black: {}, diamond: {}, yellow: {}, white: {} },
      };

      body.priceInfo.red.discountRate = this.adsData.red || null;
      body.priceInfo.diamond.discountRate = this.adsData.diamond || null;
      body.priceInfo.black.discountRate = this.adsData.black || null;
      body.priceInfo.yellow.discountRate = this.adsData.yellow || null;
      body.priceInfo.white.discountRate = this.adsData.white || null;

      ['diamond', 'black', 'red', 'yellow', 'white'].forEach(level => {
        body.priceInfo[level].payPrice = 0;
        body.priceInfo[level].fullPrice = 0;
        body.priceInfo[level].rewardPoint = null;
        body.priceInfo[level].discountPrice = this.adsData.maxPrice || null;
      });

      // NOTE: whitePoint는 존재하지 않아 삭제.
      delete body.priceInfo.white.rewardPoint;

      if (this.adsData.type === 'A') {
        ['diamond', 'black', 'red', 'yellow'].forEach(level => {
          body.priceInfo[level].rewardPoint = AD.PREMIUM_STORE_DEFAULT_POINT;
        });
      }

      // 매장 - 프리미엄 체험단 생성시 다른 등급 금액 초기화
      if (this.adsData.type === 'A') {
        ['black', 'red', 'yellow'].forEach(level => {
          body.priceInfo[level].discountRate = null;
          body.priceInfo[level].fullPrice = null;
          body.priceInfo[level].discountPrice = null;
          body.priceInfo[level].payPrice = null;
        });
      }

      return body;
    },

    getProductUpdateBody() {
      this.adsData.lastContractDate = new Date();
      this.adsData.lastContractDateInsta = new Date(this.$moment().subtract(30, 'years').format('YYYY-MM-DD'));
      this.adsData.yellowPoint = this.adsData.blackPoint = this.adsData.redPoint = this.adsData.diamondPoint;
      ['naver', 'insta'].forEach(channel => {
        if (this.adsData[channel]) {
          this.adsData[channel].revisitPeriod = this.adsData.category.firstName === '매장' ? 60 : 60;
          if (this.adsData[channel].number > 0) {
            this.adsData[channel].current = 0;
          }
          this.adsData[channel].blackPayment = this.adsData[channel].blackPayment || 0;
          this.adsData[channel].redPayment = this.adsData[channel].redPayment || 0;
          this.adsData[channel].yellowPayment = this.adsData[channel].yellowPayment || 0;
          this.adsData[channel].whitePayment = this.adsData[channel].whitePayment || 0;
          this.adsData[channel].deliveryCharge = 0;

          if (
            this.adsData[channel].black !== undefined &&
            (this.adsData[channel].diamond === null || this.adsData[channel].diamond === undefined)
          ) {
            this.adsData[channel].diamond = this.adsData[channel].black;
          }
          if (
            this.adsData[channel].blackFullPrice !== undefined &&
            (this.adsData[channel].diamondFullPrice === null || this.adsData[channel].diamondFullPrice === undefined)
          ) {
            this.adsData[channel].diamondFullPrice = this.adsData[channel].blackFullPrice;
          }
          if (
            this.adsData[channel].blackPrice !== undefined &&
            (this.adsData[channel].diamondPrice === null || this.adsData[channel].diamondPrice === undefined)
          ) {
            this.adsData[channel].diamondPrice = this.adsData[channel].blackPrice;
          }
          if (
            (this.adsData[channel].blackPayment !== undefined &&
              (this.adsData[channel].diamondPayment === null || this.adsData[channel].diamondPayment === undefined)) ||
            (!!this.adsData[channel].blackPayment && this.adsData[channel].diamondPayment === 0)
          ) {
            this.adsData[channel].diamondPayment = this.adsData[channel].blackPayment;
          }

          if (this.adsData.type === 'A' && this.adsData.deliveryMethod === 'PR') {
            this.adsData[channel].diamondPayment = this.adsData[channel].diamondFullPrice;
          }
        }
      });

      this.adsData.code = '55';
      const body = { ...this.adsData };

      /*
          rereceiptReviewRequired => false 라는 것은 영수증 리뷰가 필수가 아닌 것으로 화이트 등급이 참여가 불가능
          enableWhite => false 라는 것은 화이트 등급을 활성화 하지 않겠다는 뜻
          */
      if (!body.receiptReviewRequired || !body?.naver?.enableWhite) {
        ['white', 'whiteFullPrice', 'whitePayment', 'whitePrice'].forEach(key => {
          if (body['naver']) {
            delete body['naver'][key];
          }
        });
      }

      // 매장 - 프리미엄 체험단 생성시 다른 등급 금액 초기화
      if (body.category?.firstName === '매장' && body.type === 'A') {
        ['black', 'red', 'yellow'].forEach(level => {
          body.naver[`${level}`] = null;
          body.naver[`${level}FullPrice`] = null;
          body.naver[`${level}Price`] = null;
          body.naver[`${level}Payment`] = null;
        });
      }

      return body;
    },

    async checkRequiredInfo(step) {
      if (this.requiredMap[this.adsData.category.firstName][step]) {
        if (this.isRequiredFieldsFilled) {
          await this.nextStep(step);
        } else {
          // NOTE: 제품의 경우에만 필수 항목을 채우지 않아도 넘어가도록 설정
          if (this.adsData.category.firstName === '제품') {
            this.showRequiredInfoDlg = true;
          }
        }
      } else {
        await this.nextStep(step);
      }
    },

    async save() {
      this.adsData.email = this.adsData.email || this.user.email;
      this.adsData.user_id = this.adsData.user_id || this.user.id;
      const adsType = this.adsData.category.firstName;

      this.$store.commit('setCompanyTemp', {
        ...this.adsData,
        naver: { ...this.adsData.naver, payInfo_plan: this.adsData.plan },
      });

      try {
        if (adsType === '매장') {
          const body = this.getStoreUpdateBody();

          const res = await this.axios.post('/company3/v2', body).then(res => res.data);
          if (!this.adsData.id && res?.data?.adId) {
            this.adsData.id = res?.data?.adId;
          }
        } else {
          let res = await this.axios.post('/company3', this.adsData);

          if (!this.adsData.id && res?.data?.id) {
            this.adsData.id = res?.data?.id;
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        if (this.adsData.step === this.pages.length) {
          this.adsData.step = 1;
        } else if (this.pages[this.adsData.step - 1].done) {
          this.adsData.step = this.adsData.step + 1;
        }
      }
    },
    changeDonePreviousSteps() {
      if (this.pages.length > 0) {
        for (let i = 0; i < this.adsData.step - 1; i++) {
          let page = this.pages[i];
          page.done = true;
          this.$set(this.pages, i, page);
        }
      }
    },

    async registerStoreAds(showCardForm = false) {
      if (this.$store.getters.loading) {
        return;
      }

      this.$store.commit('setLoading', true);

      this.adsData.is_verified = 3;
      this.adsData.code = '55';

      try {
        await this.save();

        this.$store.commit('setMsg', '저장이 완료되었습니다.');
        this.$store.commit('setShowSnackbar', true);
      } catch (err) {
        console.log(err);
      } finally {
        this.$router.push(`/regads/complete?showCardForm=${showCardForm}`);

        this.$store.dispatch('getCompanyInfo', {
          email: this.user.email,
        });

        this.$store.commit('setLoading', false);
      }
    },
    async registerProductAds() {
      this.adsData.is_verified = 3;

      if (!this.$store.getters.loading) {
        this.$store.commit('setLoading', true);
        try {
          const body = this.getProductUpdateBody();
          this.adsData = body;

          await this.save();

          this.$store.commit('setMsg', '저장이 완료되었습니다.');
          this.$store.commit('setShowSnackbar', true);
        } catch (err) {
          console.log(err);
        } finally {
          this.$store.dispatch('getCompanyInfo', {
            email: this.user.email,
          });

          this.$router.push('/company/report');

          this.$store.commit('setLoading', false);
        }
      }
    },
  },
  beforeCreate() {
    this.axios
      .get('/company/option')
      .then(option => {
        this.option = option.data;
        delete this.option.category['서비스'];
      })
      .catch(err => {
        console.log(err);
      });
  },
  created() {
    this.initCompany(this.companyTemp);
    this.initPages(this.adsData.category.firstName);
    EventBus.$on('save:ads', async () => {
      await this.save();
      await this.$store.dispatch('getCompanyInfo', {
        id: this.adsData.companyId,
      });
    });

    this.$store.commit('setCompanyRegStep', this.adsData.step || 1);
  },

  beforeUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  },
  async beforeRouteLeave(to, from, next) {
    if ((this.adsData.step === 2 || this.adsData.step === 3) && this.adsData?.category?.third) {
      try {
        await this.axios.post('/utils/sendalimtalk/v2', {
          templateNumber: 2,
          variables: { '#{가맹점명}': this.user.name },
          phoneNumber: this.user.contacts.replace(/\D/g, ''),
          scheduledTime: this.$moment().add(10, 'm').format('YYYY-MM-DD HH:mm:ss'),
        });

        await this.axios.post('/utils/sendalimtalk/v2', {
          templateNumber: 15,
          phoneNumber: this.user.contacts.replace(/\D/g, ''),
          scheduledTime: this.$moment().add(24, 'h').format('YYYY-MM-DD HH:mm:ss'),
        });
      } catch (error) {
        console.error(error);
      } finally {
        next();
      }
    } else {
      next();
    }
  },
  directives: {
    DynamicEvents: {
      bind: function (el, binding, vnode) {
        let allEvents = binding.value;
        if (typeof allEvents !== 'undefined') {
          let allEventsName = Object.keys(binding.value);
          allEventsName.forEach(function (event) {
            vnode.componentInstance.$on(event, eventData => {
              allEvents[event](eventData);
            });
          });
        }
      },
      unbind: function (el, binding, vnode) {
        vnode.componentInstance.$off();
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/scss/custom';

::v-deep .dialog-button {
  width: 132px;
  height: 46px !important;
}

::v-deep h3 {
  height: 24px;
  font-size: 18px;
}

::v-deep .v-btn-toggle .v-btn:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-color: var(--primary-light-color);
}

::v-deep .v-btn-toggle .v-btn:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

::v-deep .estimate {
  border-radius: 1px;
  background-color: #fcf2f3;
  padding: 10px;
}

::v-deep .light_bulb {
  width: 14px;
  height: 20px;
  object-fit: contain;
  background-color: #00000000;
}

::v-deep .line {
  border-bottom: 1px solid #c2c2c2;
  border-bottom: 1px solid var(--pinkish-grey-color);
  width: 100%;
}

::v-deep .label {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
}

::v-deep .level_icon {
  width: 56px;
  height: 67px;
  object-fit: contain;
}

::v-deep .text-right input {
  text-align: right;
}
</style>
